import React from "react";

const BASE_WIDTH = 22;
const BASE_HEIGHT = 22;

export default function SearchIcon(props: { height?: number; width?: number }) {
  const width = props.width || BASE_WIDTH;
  const height = props.height || BASE_HEIGHT;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2233 14.8365H16.2296L15.8774 14.4969C17.1527 13.0176 17.8536 11.1292 17.8522 9.1761C17.8522 7.55902 17.3727 5.97826 16.4743 4.63371C15.5759 3.28915 14.2989 2.2412 12.805 1.62237C11.311 1.00354 9.66703 0.841629 8.08102 1.15711C6.49502 1.47258 5.03818 2.25128 3.89473 3.39473C2.75128 4.53818 1.97258 5.99502 1.65711 7.58102C1.34163 9.16703 1.50354 10.811 2.12237 12.305C2.7412 13.7989 3.78915 15.0759 5.13371 15.9743C6.47826 16.8727 8.05902 17.3522 9.6761 17.3522C11.7013 17.3522 13.5629 16.6101 14.9969 15.3774L15.3365 15.7296V16.7233L20.9187 22.2943C21.3093 22.6842 21.9419 22.6838 22.3322 22.2936L22.7936 21.8322C23.1838 21.4419 23.1842 20.8093 22.7943 20.4187L17.2233 14.8365ZM9.6761 14.8365C6.54403 14.8365 4.01573 12.3082 4.01573 9.1761C4.01573 6.04403 6.54403 3.51573 9.6761 3.51573C12.8082 3.51573 15.3365 6.04403 15.3365 9.1761C15.3365 12.3082 12.8082 14.8365 9.6761 14.8365Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}
