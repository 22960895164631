import moment from "moment";

export default function getDaysAgo(dateString?: string): number | null {
  if (!dateString) {
    return null;
  }
  const compareDate = moment(dateString).utc();
  const now = moment().utc();

  const daysAgo = now.diff(compareDate, "days");
  // const isSameDate = now.isSame(compareDate, 'date');
  // const hoursAgo = now.diff(compareDate, 'hours')

  return daysAgo;
}
